import 'promise-polyfill/src/polyfill';
import 'es6-object-assign/dist/object-assign-auto';
import 'unfetch/polyfill'; // it is needed by pbFetch

import { AdBlockDetectionService } from './services/adblock-detection.service';
import CoreService from './services/core.service';
import { AnalyticsEventsDispatcher } from './analytics/analytics-events-dispatcher';

if (!window.PlaybuzzAd) {
    CoreService.detectExecutionInsideAdPlacement().then((isDetected) => {
        if (isDetected) {
            CoreService.disableModule();
        }
    });

    if (CoreService.isEmbeddedInNonFriendlyIframe() || CoreService.isShownAsFeed()) {
        CoreService.disableModule();
    }

    AnalyticsEventsDispatcher.sendCoreInitEvent();

    AdBlockDetectionService.isAdBlockDetected().then((isDetected) => {
        if (isDetected) {
            CoreService.handleAdBlockDetection();
        } else {
            CoreService.fetchTemplate();
        }
    });
}
