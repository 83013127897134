import CoreService from './core.service';

class AdBlockDetectionService {
    static isAdBlockDetected() {
        return new Promise((resolve) => {
            fetch('https://player.ex.co/ads-home.js')
                .then(() => {
                    resolve(false);
                })
                .catch(() => {
                    // Todo: Send ad_block event
                    CoreService.disableModule();
                    resolve(true);
                });
        });
    }
}

export { AdBlockDetectionService };
