const metadata = {
    templateId: null,
    templateName: null,
    abTestName: null,
};

function setTemplateMetadata({ id = null, name = null }) {
    metadata.templateId = id;
    metadata.templateName = name;
}

function setAbTestMetadata({ abTestName = null }) {
    metadata.abTestName = abTestName;
}

function getMetadata() {
    return metadata;
}

export {
    setTemplateMetadata,
    setAbTestMetadata,
    getMetadata
};
