class ItemService {
    constructor() {
        this.item = window.pbItem || '';
    }

    get itemId() {
        return this.item ? this.item.id : '';
    }

    get isSponsored() {
        return this.item ? this.item.sponsored && this.item.sponsored.enabled : '';
    }
}

export default new ItemService();
