import { LoaderService } from './loader.service';
import CoreService from './core.service';

class AdBlockDetectionService {
    static isAdBlockDetected() {
        return new Promise((resolve) => {
            LoaderService.load('https://mcd-sdk.playbuzz.com/adwords.js')
                .then(() => {
                    resolve(false);
                })
                .catch(() => {
                    // Todo: Send ad_block event
                    CoreService.disableModule();
                    resolve(true);
                });
        });
    }
}

export { AdBlockDetectionService };
