import { AnalyticsReporter } from 'pb-client-modules/src/analytics-reporter';
import { getPageIdentifier } from 'pb-client-modules/src/page-identifier';
import { UserCookie } from 'pb-client-modules/src/user-cookie';
import ItemService from '../services/item.service';
import DeviceService from '../services/device.service';
import { EnvironmentService } from '../services/environment.service';

/* Singleton Class */
class AnalyticsReporterService {
    constructor() {
        // both for staging and production
        const userConfig = {
            env: 'PROD',
        };

        this.analyticsReporter = new AnalyticsReporter(userConfig);
        this.analyticsReporter.addGlobalAttributes({
            pageloadUid: getPageIdentifier(),
            articleId: ItemService.itemId,
            adDeviceType: DeviceService.isDesktop ? 'desktop' : 'mobile'
        });

        this._setAnonIfNeeded();
    }

    report(event, data) {
        Promise.resolve(this.analyticsReporter.report(event, data));
    }

    _setAnonIfNeeded() {
        if (EnvironmentService.isEmbed) {
            this.analyticsReporter.setAnonymous();
        } else {
            // if it's not embed UserCookie.isLoggedIn() is synchronous
            UserCookie.isLoggedIn().then((isLoggedIn) => {
                // Send to anonymous if the user is not logged in.
                if (!isLoggedIn) {
                    this.analyticsReporter.setAnonymous();
                }
            });
        }
    }
}

export default new AnalyticsReporterService();
