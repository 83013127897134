import { UrlParser } from '@playbuzz/url';
import { getQueryStringValue } from 'pb-client-modules/packages/utils';

export class EnvironmentService {
    static getUrlParam(paramName) {
        return getQueryStringValue(paramName);
    }

    static getPlaybuzzAdsModuleUrl() {
        return window.pbAdsSettings && window.pbAdsSettings.resources
            ? window.pbAdsSettings.resources.playbuzzAdsModule
            : '';
    }

    static get isProd() {
        try {
            // Get the current env (set by WebpackDefinePlugin).
            return !process.env.NODE_ENV || process.env.NODE_ENV === 'production';
        } catch (e) {
            // fallback to production
            return true;
        }
    }

    static get isEmbed() {
        return window !== window.parent;
    }

    static get pageLoadUid() {
        return window.pbPageIdentifier || window.plbzPid;
    }

    static getReferrerUrl() {
        try {
            if (!window.utmCookie) {
                return encodeURIComponent(document.location.href);
            }
            const href = new UrlParser(document.location.href);
            if (window.utmCookie.getSource()) {
                href.queryParams.set('utm_source', window.utmCookie.getSource());
            }
            if (window.utmCookie.getMedium()) {
                href.queryParams.set('utm_medium', window.utmCookie.getMedium());
            }
            if (window.utmCookie.getTerm()) {
                href.queryParams.set('utm_term', window.utmCookie.getTerm());
            }
            return encodeURIComponent(href.toString());
        } catch (ex) {
            return encodeURIComponent(document.location.href);
        }
    }

    static get parentUrl() {
        return document.location.href;
    }

    static get sessionParentHost() {
        return document.location.hostname;
    }
}
