import AnalyticsReporterService from './analytics/analytics-reporter';
import CoreService from './services/core.service';
import * as MetadataService from './services/metadata.service';

if (!window.PlaybuzzAd) {
    class PlaybuzzAd {
        /**
         * @param {*} placementConfig -
         *              element: dom element,
                        index: section index,
                        length: number of section,
                        position: placement position,
                        section: section name
         */
        constructor(placementConfig) {
            if (isModuleDisabled) {
                this.adFromModule = emptyAd;
            }

            // preventing case when there are two quizes with exact same number of questions
            if (!placementConfig.seed) {
                placementConfig.seed = Math.round(Math.random() * 1e8);
            }

            this.placementConfig = placementConfig;

            // if module loaded use createAd in order to register the ad and keep instance of the ad
            if (PlaybuzzAd.module) {
                this.adFromModule = PlaybuzzAd.module.createAd(placementConfig);
                // if module was not loaded, genereate hashKey and store the placement config in deferredAds object with the hashKey
            } else {
                this.hashKey = `${placementConfig.section}__${placementConfig.position}__${placementConfig.index}__${placementConfig.seed}`;
                PlaybuzzAd.deferredAds = PlaybuzzAd.deferredAds || {};

                const deferredAd = { placementConfig };
                deferredAd.showPromise = new Promise((resolve) => {
                    this._resolveShow = resolve;
                });

                this._completePromise = new Promise((resolve) => {
                    deferredAd.completionResolver = resolve;
                });

                PlaybuzzAd.deferredAds[this.hashKey] = deferredAd;
            }
        }

        /**
         * @desc Function responsible for showing the ad.
         * Returns a promise when the ad is done.
         */
        show() {
            // if module loaded return the promise from PlaybuzzAd.module
            if (this.adFromModule) {
                return this.adFromModule.show();
                // if module wasn't loaded, create a new promise and update the deferredAds hash with a resolve function
            }
            this._resolveShow();
            return this._completePromise;
        }

        /**
         * Updates ad with new data
         * @param placementConfig
         */
        update(placementConfig) {
            if (this.adFromModule) {
                this.adFromModule.update(placementConfig);
            } else if (PlaybuzzAd.deferredAds[this.hashKey].onUpdate) {
                PlaybuzzAd.deferredAds[this.hashKey].onUpdate(placementConfig);
            }
        }

        static get analyticsReporterService() {
            return AnalyticsReporterService;
        }
    }

    let isModuleDisabled = false;

    // if module is disabled all ads should be empty ads, that resolve immediately on show
    const emptyAd = {
        show: () => Promise.resolve(),
        update: () => { }
    };

    CoreService.onModuleDisable(() => {
        isModuleDisabled = true;

        if (!PlaybuzzAd || !PlaybuzzAd.deferredAds) {
            return;
        }

        Object.keys(PlaybuzzAd.deferredAds).forEach((deferredAdKey) => {
            // resolve all deferred ads
            PlaybuzzAd.deferredAds[deferredAdKey].completionResolver();
        });
    });

    PlaybuzzAd.metadata = MetadataService;

    window.PlaybuzzAd = PlaybuzzAd;
}
