import { pbFetch } from 'pb-client-modules/src/fetch';
import { UserCookie } from 'pb-client-modules/src/user-cookie';
import { getQueryStringValue } from 'pb-client-modules/src/utils';
import { EnvironmentService } from './environment.service';
import ItemService from './item.service';
import { LoaderService } from './loader.service';
import { AnalyticsEventsDispatcher } from '../analytics/analytics-events-dispatcher';
import * as MetadataService from './metadata.service';

const moduleDisableListeners = [];
let isModuleDisabled = false;

class CoreService {
    // if module wasn't loaded or shouldn't be loaded, execute callback
    static onModuleDisable(callback) {
        if (!(callback instanceof Function)) {
            return;
        }

        if (isModuleDisabled) {
            callback();
        } else {
            moduleDisableListeners.push(callback);
        }
    }

    static disableModule() {
        // no need to disable module twice
        if (isModuleDisabled) {
            return;
        }

        isModuleDisabled = true;

        moduleDisableListeners.forEach((callback) => {
            callback();
        });
    }

    static detectExecutionInsideAdPlacement() {
        return new Promise((resolve) => {
            try {
                let w = window;
                do {
                    w = w.parent;
                    // If it's unified content unit, module should not be disabled
                    if (w.PlaybuzzAd && !w.PlaybuzzAd.getUnifiedUnitTemplate) {
                        resolve(true);
                        return;
                    }
                } while (w.parent !== w);
            } catch (e) {
                // If detecting PlyabuzzAd in one of parents frame has gone wrong (secured frame), no action should be taken
            }

            resolve(false);
        });
    }

    static _onFetchTemplateSuccess(response) {
        AnalyticsEventsDispatcher.sendTemplateReceivedEvent(response);

        if (response.template) {
            MetadataService.setTemplateMetadata({
                id: response.template._id,
                name: response.template.name
            });
        }

        this._loadModule(response);
    }

    static _onFetchTemplateFailure(error) {
        AnalyticsEventsDispatcher.sendTemplateRequestErrorEvent(error);
        this.disableModule();
    }

    static _loadModule(data) {
        if (data.template || !EnvironmentService.isEmbed) { // always load the module for pb.com
            const url = EnvironmentService.getPlaybuzzAdsModuleUrl();
            if (!url) {
                this.disableModule();
                // Todo Logger: log error - playbuzzAdsModule url doesn't exist
            }

            LoaderService.load(url)
                .then(() => {
                    if (!window.PlaybuzzAd.module) {
                        // Todo Logger: PlaybuzzAd.module does not exist
                    }

                    window.PlaybuzzAd.module.setConfig(data.template);

                    window.PlaybuzzAd.module.provide('anonymousId', () => {
                        UserCookie.init(EnvironmentService.isProd ? 'production' : 'staging', getQueryStringValue('pbdebug'));
                        return Promise.race([
                            UserCookie.getAnonymous(),
                            new Promise((resolve, reject) => setTimeout(reject, 2000))
                        ]);
                    });
                    // Todo Logger: playbuzzAdsModule was loaded (module success)
                })
                .catch(() => {
                    this.disableModule();
                    // Todo Logger: log error failed to load (module failure)
                });
        } else {
            this.disableModule();
            // Todo Logger: no template
        }
    }

    static fetchTemplate() {
        // If parent frame exists and is "friendly" and has a template, it should be used
        // This feature is used by unified-content-unit
        try {
            const hasUnifiedContentUnitInParentFrame = window.parent && window.parent.PlaybuzzAd && window.parent.PlaybuzzAd.getUnifiedUnitTemplate;
            if (hasUnifiedContentUnitInParentFrame) {
                window.parent.PlaybuzzAd.getUnifiedUnitTemplate().then(({ template }) => {
                    this._onFetchTemplateSuccess({ template });
                });

                return;
            }
        } catch (e) {
            // Do nothing since accessing parent frame may throw excption if it is secured
        }

        const adsServiceUri = EnvironmentService.isProd
            ? 'https://ads.playbuzz.com/api/v1/template'
            : 'https://stg-ads.playbuzz.com/api/v1/template';
        const { itemId } = ItemService;
        const pbadsTemplateName = EnvironmentService.getUrlParam('pbads_preview');

        const referrer = EnvironmentService.getReferrerUrl();

        let url = `${adsServiceUri}?itemId=${itemId}&referrer=${referrer}`;

        if (pbadsTemplateName !== null) {
            url += `&pbads_template=${pbadsTemplateName}`;
        }

        if (ItemService.isSponsored) {
            url += '&sponsored=true';
        }

        // Todo Logger: template request
        AnalyticsEventsDispatcher.sendTemplateRequestEvent();
        pbFetch.get(url, { credentials: 'include' })
            .then(this._onFetchTemplateSuccess.bind(this))
            .catch((error) => {
                this._onFetchTemplateFailure(`exception in template request - ${error.message} `);
            });
    }

    static handleAdBlockDetection() {
        AnalyticsEventsDispatcher.sendAdblockDetectionEvent();
    }

    static isEmbeddedInNonFriendlyIframe() {
        return (window.location.hostname === 'www.playbuzz.com') && (window !== window.top);
    }

    static isShownAsFeed() {
        const isOnPbCom = window.location.hostname.indexOf('playbuzz.com') > -1;
        const isFeed = EnvironmentService.getUrlParam('feed') === 'true';

        return !EnvironmentService.isEmbed && isOnPbCom && isFeed;
    }
}

export default CoreService;
