import AnalyticsReporterService from './analytics-reporter';

class AnalyticsEventsDispatcher {
    static sendCoreInitEvent() {
        AnalyticsReporterService.report({
            name: 'ad_module_core_init'
        }, {});
    }

    static sendAdblockDetectionEvent() {
        AnalyticsReporterService.report({
            name: 'ad_blocked'
        }, {});
    }

    static sendTemplateRequestEvent() {
        AnalyticsReporterService.report({
            name: 'ads_template_request',
        }, {});
    }

    static sendTemplateReceivedEvent(data) {
        const event = {
            extraData: {
                templateMessage: data.message,
                templateInd: data.template ? 1 : 0,
            },
        };

        AnalyticsReporterService.report({
            name: 'ads_template_received'
        }, event);
    }

    static sendTemplateRequestErrorEvent(errorMessage) {
        const event = {
            adErrorDescription: errorMessage || 'no error message',
        };

        AnalyticsReporterService.report({
            name: 'ads_template_request_error'
        }, event);
    }
}


export { AnalyticsEventsDispatcher };
