import { setDeviceInfo, isDesktop } from 'pb-client-modules/src/device-detector';

class DeviceService {
    constructor() {
        setDeviceInfo(window.pbDeviceInfo);
    }

    get isDesktop() {
        return isDesktop();
    }
}

export default new DeviceService();
