import { setDeviceInfo, isDesktop } from '@playbuzz/device-detector';

class DeviceService {
    constructor() {
        setDeviceInfo(window.pbDeviceInfo);
    }

    get isDesktop() {
        return isDesktop();
    }
}

export default new DeviceService();
